import React, { useEffect, useState } from 'react';  
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss';
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

const Loading = () => (
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
);

const Header = React.lazy(() => import('./views/partials/Header'));
const Footer = React.lazy(() => import('./views/partials/Footer'));

const Index = React.lazy(() => import('./views/pages/Index'));
const Shop = React.lazy(() => import('./views/pages/Shop'));
const Cart = React.lazy(() => import('./views/pages/Cart'));
const Download = React.lazy(() => import('./views/pages/Download'));
const Contact = React.lazy(() => import('./views/pages/Contact'));
const PaymentSuccess = React.lazy(() => import('./views/pages/PaymentSuccess'));

const App = () => {
  const [navBackground, setNavBackground] = useState('#1b1b1e');

  const setNavBackgroundColor = (color) => {
    setNavBackground(color);
  };

  const setBodyBackgroundColor = (color) => {
    document.body.style.background = color;
  };

  return (
    <BrowserRouter>
      <Header background={navBackground} setBackground={setNavBackgroundColor} />
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Index setNavBackground={setNavBackgroundColor} setBodyBackgroundColor={setBodyBackgroundColor} />} />
          <Route path="/shop" element={<Shop setNavBackground={setNavBackgroundColor} setBodyBackgroundColor={setBodyBackgroundColor} />} />
          <Route path="/cart" element={<Cart setNavBackground={setNavBackgroundColor} setBodyBackgroundColor={setBodyBackgroundColor} />} /> 
          <Route path="/download" element={<Download setNavBackground={setNavBackgroundColor} setBodyBackgroundColor={setBodyBackgroundColor} />} /> 
          <Route path="/contact" element={<Contact setNavBackground={setNavBackgroundColor} setBodyBackgroundColor={setBodyBackgroundColor} />} />
          <Route path="/callbacks/payment/success" element={<PaymentSuccess setNavBackground={setNavBackgroundColor} setBodyBackgroundColor={setBodyBackgroundColor} />} /> 
        </Routes>
        <Footer />
      </React.Suspense>
    </BrowserRouter>
  )
};

export default App;